html, body, .App, #app, #app>div, #root {
  height: 100%
}

#root {
  height: 100%;
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
}

.header {
  /* background-color: white;
  padding: 1rem;
  height: 60px;
  top: 0; */

  height: 54px;
  background-color: white;
  top: 0px;
  z-index: 100;
  align-items: center;
  transition: all 0.15s ease-in-out 0s;
  position: sticky;
  /* will-change: padding, box-shadow, background-color; */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
}

.header-logo {
  display: block;
  height: 28px;
}

.bmbf-container {
  display: flex;
  flex-direction: column;
  font-family: "Helvetica Now Text", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.bmbf-text {
  font-size: 8px;
}

.bmbf-logo-container {
  display: block;
}

.bmbf-logo {
  height: 42px;
}

.default-button {
  background-color: rgb(75, 106, 0) !important;
}

.default-button:disabled {
  background-color: #A7B4B8 !important;
  color: #fff !important;
}

.default-button:hover {
  background-color: #4B6A00 !important
}

.default-button-white {
  box-shadow: none !important;
  border-width: 2px !important;
  border-color: black !important;
  border-radius: 4px !important;
  background-color: white !important;
  color: #4B6A00 !important;
}

.default-button-white:hover {
  box-shadow: none !important;
  border-width: 2px !important;
  border-color: rgb(75, 106, 0) !important;
  border-radius: 4px !important;
  background-color: white !important;
  color: rgb(75, 106, 0) !important;
}

p {
  color: black;
  /* font-family: '"Helvetica Now Text", Helvetica, Arial, sans-serif'; */
  font-weight: 400;
  font-size: '16px';
  line-height: '24px';
}